<template>
  <div class="sidebar">
    <ClientOnly>
      <div>
        <SidebarSideBarOption
          v-for="(option, index) in prefixOptions"
          :key="option.name"
          :name="option.name"
          :display-text="$t(option.translationKey)"
          :icon="option.icon"
          :style="{ paddingTop: index === 0 ? '0' : '1.5rem' }"
          :selected-option="selectedData"
          @click="onClick(option)"
        />
      </div>
      <div>
        <SidebarSideBarOption
          v-for="option in suffixOptions"
          :key="option.name"
          :name="option.name"
          :display-text="$t(option.translationKey)"
          :icon="option.icon"
          :selected-option="selectedData"
          @click="onClick(option)"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { onMounted, ref, Ref } from "vue";
import { navigateTo, useRuntimeConfig } from "nuxt/app";
import routes from "~/constants/routes";
import { useSearchStore } from "@/store/search";
import { useNavigationStore } from "@/store/navigation";
import { useUserStore } from "@/store/user";
import { useOrgStore } from "@/store/org";
import { useLogout } from "~/composables/useLogout";
import { useApiCall } from "~/composables/useApiCall";
import RequestOptions from "~/types/RequestOptionsType";

const searchStore = useSearchStore();
const navigationStore = useNavigationStore();
const selectedData: Ref = ref("");
const auth0 = useAuth0();
const userStore = useUserStore();
const user = process.client ? userStore.getUser : null;
const orgStore = useOrgStore();
const org = process.client ? orgStore.getOrg : null;
const intercomHelpActive = ref(false);
const config = useRuntimeConfig();

const populatePrefixOptions = () => {
  let prefixOptions = routes.filter((route) => route.isPrefix);

  // Check if User has is_vcdb_only
  if (org?.attributes?.is_vcdb_only) {
    prefixOptions = prefixOptions.filter((route) => route.isVcdbOnly);
  }

  // Check if User has pitchWorkflow turned off
  if (!org?.attributes?.plan_features?.pitchWorkflow) {
    prefixOptions = prefixOptions.filter((route) => route.name !== "Contacts");
  }

  // Check if User has Social Access
  if (!userStore?.allowSocialAccess) {
    prefixOptions = prefixOptions.filter((route) => route.name !== "Keyhole");
  }

  return prefixOptions.map((route) => {
    return {
      name: route.name,
      translationKey: route.translationKey,
      icon: route.icon,
    };
  });
};

const prefixOptions: Ref = ref(populatePrefixOptions());

const suffixOptions: Ref = ref(
  routes
    .filter((route) => !route.isPrefix)
    .map((route) => {
      let key = route.translationKey;
      if (route.name === "ExitUser") {
        if (user?.is_impersonated) {
          key = "sidebar.exituser";
        }
      }

      return {
        name: route.name,
        translationKey: key,
        icon: route.icon,
      };
    }),
);

const checkSocialTrackingStatus = () => {
  if (!user?.social_listening_access) {
    return false;
  }
  return searchStore?.searches?.some(
    (search) => search?.attributes?.social_tracker_enabled === true,
  );
};

const redirectFunc = async () => {
  const options: RequestOptions = { method: "GET" };
  const ssoUrl = await useApiCall(`/users/${user?.id}/sso_login`, options);

  if (ssoUrl?.data?.value?.redirect_url) {
    window.open(`${ssoUrl.data.value.redirect_url}`, "_blank");
  }
};

const socialStatus = async () => {
  const hasSocialTrackers = checkSocialTrackingStatus();
  if (hasSocialTrackers) {
    await redirectFunc();
  }
};

const intercomHelp = () => {
  if (process.client && window.Intercom && config.public.IS_PROD) {
    if (intercomHelpActive.value) {
      window.Intercom("hide");
      intercomHelpActive.value = false;
    } else {
      intercomHelpActive.value = true;
      window.Intercom("show");
    }
  }
};

const onClick = async (opt: { name: string; displayText: string }) => {
  if (opt.name === "Help") {
    intercomHelp();
    return;
  }
  if (opt.name === "Keyhole") {
    await socialStatus();
  }
  if (opt.name === "ExitUser") {
    if (user?.is_impersonated) {
      await useApiCall(`/users/${user?.id}/end_impersonation`, {
        method: "PUT",
      });
    }
    useLogout(auth0);
  }
  selectedData.value = opt.name;
  navigationStore.setActiveNavBarGroup(selectedData.value);

  let navPath = navigationStore?.activeNavBarOptions?.[0].path;
  if (opt.name === "Settings" && user) {
    navPath = `${navPath}/user/${user?.id}`;
  }

  await navigateTo(navPath, {
    external: navigationStore?.activeNavBarOptions?.[0].isExternalRoute,
  });
};

onMounted(() => {
  // Retrieve active option based from store
  selectedData.value = navigationStore.activeSideBarOption;
});
</script>

<style scoped>
.sidebar {
  @apply h-[calc(100vh-5rem)] z-10 w-24 bg-white-200 flex flex-col justify-between pb-5 fixed top-20;
}
</style>
