export const defaultFlag = {
  name: "",
  isActive: false,
};

export default [
  {
    routeId: 5,
    name: "authorsAndPublicationsTab",
    isActive: false,
  },
];
