import { defineStore } from "pinia";
import flags from "~/constants/flags";
import Flag from "~/types/Flag";

type State = {
  flags: Flag[] | null;
};
export const useLaunchDarklyStore = defineStore("launchDarkly", {
  state: (): State => ({
    flags,
  }),
  getters: {
    getFlags(state) {
      return state.flags;
    },
  },
  actions: {
    getFlagByName(name: string) {
      return this.flags?.find((x) => x.name === name);
    },
    updateFlagValue(name: string, value: boolean) {
      const foundFlag = this.getFlagByName(name);

      if (foundFlag) foundFlag.isActive = value;
    },
  },
});
