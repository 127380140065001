import * as LaunchDarkly from "launchdarkly-js-client-sdk";
import { useRuntimeConfig } from "nuxt/app";
import { useLaunchDarklyStore } from "~/store/launchDarkly";

export const useLaunchDarkly = () => {
  const config = useRuntimeConfig();
  const launchdarklyStore = useLaunchDarklyStore();

  const getClient = (context: LaunchDarkly.LDContext) => {
    return LaunchDarkly.initialize(config.public.LD_KEY as string, context);
  };

  const getFlagValue = (
    client: LaunchDarkly.LDClient,
    flag: string,
    defaultVal: boolean = false,
  ) => {
    return client.variation(flag, defaultVal);
  };

  const updateFlagValue = (context: LaunchDarkly.LDContext, flag: string) => {
    const client = getClient(context);
    client.identify(context, "", async () => {
      const flagValue = getFlagValue(client, flag);
      launchdarklyStore.updateFlagValue(flag, flagValue);
    });
  };

  return {
    getClient,
    getFlagValue,
    updateFlagValue,
  };
};
